import axios from 'axios';
import config from "../lib/config.js";
import {Encryptdata} from "../lib/Encrypdycrpt.js"
import { AppenData, axiosFunc } from '../lib/common.js';

export const loginAdmin = async(data)=>{
    let datas = Encryptdata(data)

    var senddata    =   {
        method  :   'POST',
        url     :   `${config.AdminAPI}/adminlogin`,
        data  :   { data: datas },
        'headers': {
          'contentype':'application/json',
        }
        }
        let Resp    =   await axiosFunc(senddata)
        console.log("dfsdfsfgfsg",Resp);
        if(Resp.data.token)
        localStorage.setItem("token",Resp.data.token);

        return Resp.data

}
export const getarticle=async(data)=>{

    var encdata = Encryptdata(data)

        var senddata = {
            "method":"GET",
            "url":`${config.AdminAPI}/getarticle`,
            params   :   { data: encdata } ?? {},
        }
        let Resp    =   await axiosFunc(senddata)
        console.log("fgdfgdghggh",Resp);

        return Resp.data

   
}

export const getaboutuser=async()=>{
    try{
        var resp = await axios({
                'method':'GET',
                'url':`${config.AdminAPI}/getaboutuser`,
        })
        console.log("member resp",resp.data)
        return resp.data;
    }
    catch(err){console.log("err in article list",err)}
}

export const customtokenfetch=async(data)=>{


        var encdata = Encryptdata(data)
    
        var senddata = {
            "method":"post",
            "url":`${config.AdminAPI}/tokenfetchapi`,
            data   :   { data: encdata } ?? {},       
            "headers":{
                "Authorization":localStorage.getItem("token"),
                'contentype':'application/json',
            },
        
        }
        let Resp    =   await axiosFunc(senddata)
        if(Resp.data.token){
        localStorage.setItem("token",Resp.data.token);
        }
        return Resp.data
    
    
    }




export const check  = async()=>{

    try{
    var data = {"test":"testdata"}
    var resp = await axios({
        "method":"POST",
        'url':`${config.AdminAPI}/checkroute`,
        "data":data,
        "headers":{
            "Authorization":localStorage.getItem("token")
        }
    })

    }
    catch(err){console.log("err".err)}

}
export const createProject = async (data) => {
    var formdata = AppenData(data)
    try {
        // var data = { "test": "testdata" }
     


        var senddata = {
            "method":"post",
            "url":`${config.AdminAPI}/createProject`,
            "data": formdata[0],
            "headers":{
                "Authorization":localStorage.getItem("token"),
                // 'contentype':'application/json',
            },
        
        }

        let Resp    =   await axiosFunc(senddata)

        return Resp.data

    }
    catch (err) { console.log("err".err) }

}

export const stakingFunctions = async (data) => {
    var formdata = AppenData(data)
    try {
        // var data = { "test": "testdata" }
     


        var senddata = {
            "method":"post",
            "url":`${config.AdminAPI}/stakingFunctions`,
            "data": formdata[0],
            "headers":{
                "Authorization":localStorage.getItem("token"),
                // 'contentype':'application/json',
            },
        
        }

        let Resp    =   await axiosFunc(senddata)

        return Resp.data

    }
    catch (err) { console.log("err".err) }

}