import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { LoadToast, isEmpty, updateToast, validation } from "../../lib/common";
import * as adminFunctions from "../../axioscalls/admin.js";
import {
  Account_Connect,
  Account_disConnect,
  Initial_Connect,
  Admin_Login,
} from "../../redux/action.js";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

toast.configure();

export default function Login(props) {
  // var location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const Wallet_Details = useSelector((state) => state.wallet_detail);

  useEffect(() => {
    console.log("Props : ", props.data);
    console.log("admin_login", Wallet_Details.Login_Admin);
    if (localStorage.getItem("adminlogin") == "yes") {
      history.push("/dashboard");
    }
  }, [Wallet_Details.userAccountAddr]);

  const initialValue = {
    email: "",
    password: "",
    path: "login",
  };

  const [formValue, setFormValue] = useState(initialValue);
  const [validErrors, setValidErrors] = useState("");
  const [loc, setLoc] = useState("");

  const handleSubmit = async () => {
    const id = LoadToast();
    let err = await validation(
      Object.keys(initialValue),
      formValue,
      "register"
    );

    if (Object.keys(err).length > 0) {
      updateToast(id, "fix");
      setValidErrors(err);
      return;
    }
    setValidErrors({});

    // if (resp) setValidErrors(resp)
    var { success, error, msg } = await adminFunctions.loginAdmin(formValue);

    updateToast(
      id,
      success == "success" ? "Login Successfully" : error,
      success
    );
    if (success == "success") {
      localStorage.setItem("adminlogin", "yes");

      history.push("/dashboard");
    } else setValidErrors(error);
  };

  const handlechange = (e) => {
    const { id, value } = e.target;
    setFormValue({ ...formValue, [id]: value });
  };

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="card text-left py-5 px-4 px-sm-5">
              <div className="brand-logo">
                {/* <img src={require("../../assets/images/logos.png")} alt="logo" /> */}
              </div>
              <h4>Hello! let's get started</h4>
              <h6 className="font-weight-light">Sign in to continue.</h6>
              <Form className="pt-3">
                <Form.Group className="d-flex search-field">
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    size="lg"
                    className="h-auto adm_signin_inpt"
                    id="email"
                    value={formValue?.email}
                    onChange={(e) => handlechange(e)}
                  />
                </Form.Group>
                <p>{validErrors?.email}</p>
                {/* <Form.Group className="d-flex search-field">
                    <Form.Control type="password" placeholder="Password" size="lg" className="h-auto" id="password" value={formValue.password} onChange={(e)=>handlechange(e)} />
                  </Form.Group> */}
                <div className="input-group passwordinput adm_sigin_passwordinpt">
                  <input
                    type={passwordType}
                    onChange={(e) => handlechange(e)}
                    value={formValue?.password}
                    id="password"
                    class="form-control pw adm_singin_pass_inpt"
                    placeholder="Enter Password"
                  />
                  <div className="input-group-btn">
                    <button
                      className="btn btn-outline-primary eyebtns adm_signin_eye_btn"
                      type="button"
                      onClick={() => togglePassword()}
                    >
                      {passwordType != "password" ? (
                        <AiFillEye />
                      ) : (
                        <AiFillEyeInvisible />
                      )}
                    </button>
                  </div>
                </div>
                <p>{validErrors?.password}</p>

                <Link className="font-weight-light" to={"/emailverify"}>
                  Forget password
                </Link>

                <div className="mt-3">
                  <p
                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn allbtn allbtns"
                    onClick={() => handleSubmit()}
                  >
                    SIGN IN
                  </p>
                </div>

                {/* <div className="my-2 d-flex justify-content-between align-items-center">
                    <div className="form-check">
                      <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input"/>
                        <i className="input-helper"></i>
                        Keep me signed in
                      </label>
                    </div>
                    <a href="!#" onClick={event => event.preventDefault()} className="auth-link text-muted">Forgot password?</a>
                  </div>
                
                  <div className="text-center mt-4 font-weight-light">
                    Don't have an account? <Link to="/user-pages/register" className="text-primary">Create</Link>
                  </div> */}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
