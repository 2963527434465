import axios from "axios";
import config from "../lib/config";
import { Decryptdata, Encryptdata } from "./Encrypdycrpt";
import { toast } from "react-toastify";

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export const NumANdDotOnly = (data) => {
  var data = data.toString();
  var str = data
    ? data.includes(".")
      ? data.split(".").length >= 3
        ? (data.split(".")[0] + "." + data.split(".")[1]).toString()
        : data
      : data
    : data;
  return str.toString().replace(config.NumDigitOnly, "");
};
export const NumberOnly = (data) => {
  return data.toString().replace(config.NumberOnly, "");
};


// Common Axios Function

export const axiosFunc = async (data) => {
  try {
    let Resp    =   {};

      Resp    =  await axios(data)
      if(Resp?.data){
          Resp.data = isEmpty(Decryptdata(Resp.data)) ? Resp.data : Decryptdata(Resp.data)
      }
      return Resp
  } catch (e) {
    console.log('axiosFunc_error',e)
    return { success: "error", msg: null };
  }
};



export const validation =(requirefield,data,from)=>{
  //* data must be an object *//

  let err={}
  requirefield.forEach(function(field){
        if(data.hasOwnProperty(field)){
          if(data[field]){
            if((field.toLowerCase() == "password"||field.toLowerCase() == "newpassword"||field.toLowerCase() == "confirmpassword")&&from!="register"){
              if(!config.PASSWORD.test(data[field])){
                  err = {...err,...{[field] : field + " must be contain alpha numeric"}}
              }
            }
            if(field.toLowerCase() == "emailid"){
              if(!config.EMAIL.test(data[field])){
                  err = {...err,...{[field] : field + " Enter valid email"}}
              }
            }
            if(field.toLowerCase() == "otp"){
              if(!config.OTP.test(data[field])){
                  err = {...err,...{[field] : field + " Enter  4 digit OTP"}}
              }
            }
            if(field.toLowerCase() == "mobileno"){
              if(!config.MOBILE.test(data[field])){
                  err = {...err,...{[field] : field + " Enter  10 digit Mobile Number"}}
              }
            }

          }else{

              err = {...err,...{[field] : field + " is required"}}
          }
        }else{
          err = {...err,...{[field] : field + " doesn't exist in object"}}
        }
      });
    return err;

}


export const LoadToast = (data) => {return toast.loading(data??"Loading.....")}
export const updateToast = (id,data,type) => {

  try{
    if (id) {
      if (data == "fix") {
        data = "Fix All Validation"
        type = "error"
      }
      if (type == "error") type = toast.TYPE.ERROR
      if (type == "success") type = toast.TYPE.SUCCESS
      if (type == "info") type = toast.TYPE.INFO

      setTimeout(()=>{
        return toast.update(id, { render: data, type: type, isLoading: false, autoClose: 1000 })

      },1000)
      //
      // return toast.update(id, { render: data, type: type, autoClose: 1000 })
    }
    else {
      //
      return toast[type](data, 1000 )
    }
}
catch(e){
    console.error("tpast update",e)
    return false
}
}
export const AppenData = (data) => {
  var formdata = new FormData()
  var SendDta = Object.entries(data).map((item) => {
      if (Array.isArray(item[1])) {
          var come = item[1].map((data) => {
              if (data?.type && data?.size) {
                  //file
                  formdata.append(item[0], data)
              }
              else {
                  formdata.append(item[0], Encryptdata(data))

              }
              return formdata
          })
          return come

      }
      else {
          if (item[1]?.type && item[1]?.size) {
              //file type
              formdata.append(item[0], item[1])
          }
          else {
              formdata.append(item[0], Encryptdata(item[1]))

          }
          return formdata
      }
  })
  return [formdata]
} 
export const address_showing = (item) => {
  if (item && item.toString().length > 10) {
    var slice_front = item.slice(0, 9)
    var slice_end = item.slice(item.length - 9, item.length - 1)
    return slice_front + '....' + slice_end
  }
  else return item
}


export function calculateStakingDaysPassed(eventStartDate, eventEndDate, stakingStartDate, stakingEndDate) {
  // Parse the input strings to Date objects
  const eventStart = new Date(eventStartDate);
  const eventEnd = new Date(eventEndDate);
  const stakingStart = new Date(stakingStartDate);
  const stakingEnd = new Date(stakingEndDate);

  // Get the current date
  const currentDate = new Date();

  // Determine the overlap end date (min of event end date, staking end date, and today's date)
  const overlapEnd = new Date(Math.min(eventEnd, stakingEnd, currentDate));

  // If the overlap end date is before the staking start date or event start date, there is no overlap
  if (overlapEnd < stakingStart || overlapEnd < eventStart) {
    return 0;
  }

  // Calculate the number of days within the overlap
  const timeDiff = overlapEnd - Math.max(stakingStart, eventStart);
  const daysOfStaking = Math.floor(timeDiff / (1000 * 60 * 60 * 24)) + 1;

  return daysOfStaking;
}
export const getDaysOfDesiredMonth = (Season) => {

  var month;

  if (Season == "Season 1") month = 3; // next march last date
  else if (Season == "Season 2") month = 6; // next may last date
  else if (Season == "Season 3") month = 9; // next june last date
  else if (Season == "Season 4") month = 12; // next oct last date

  // Get the current date
  var currentDate = new Date();

  // Set the target date to March 1 of the current year
  var targetDate = new Date(currentDate.getFullYear(), month, 1); // Note: Months are 0-indexed, so March is represented by 2
  console.log("targetDate", targetDate);
  // Calculate the difference in days
  var timeDifference = targetDate.getTime() - currentDate.getTime();
  var daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  console.log("Days until", daysDifference);

  var poolStartDate = new Date(currentDate.getFullYear(), month - 3, 1);

  // days difference from now and previous day of 1
  return {
    days: (daysDifference - 1) < 0 ? 0 : daysDifference - 1,
    dateFormat: new Date(targetDate.setDate(targetDate.getDate() - 1)).toISOString(),
    startDate: new Date(new Date().setHours(24, 0, 0, 0)).toISOString(),
    poolStartDate: poolStartDate.toISOString()
  }
}