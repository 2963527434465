
import Web3 from 'web3';
import config from '../lib/config';
import WalletConnectProvider from "@walletconnect/web3-provider";
import network from '../lib/network';
import Trade from '../ABI/trade.json';


export const Instance = async(provider, address)=>{


  const web3 = new Web3(provider)

  
  if(web3){
    var contract_instance = new web3.eth.Contract(
        Trade, address ? address : config.tradeAddress
    );

    return contract_instance;
  }else{
    console.log("no instance created")
  }


}

export const connect_Wallet = async (type,switchchain,provideralone) => {
    console.log("type", type);
    let provider = null;
    if (type == "wc") {
        provider = new WalletConnectProvider({
            rpc: {
                56: "https://bsc-dataseed.binance.org",
                // 43313: "https://api.avax-test.network/ext/bc/C/rpc" //avax
            },
            network: "binance",
            chainId: 56,
            // network: "avalanche",
            // chainId: 43113
        });
        localStorage.setItem("walletConnectType", type);

        //   return provider;
    }
    else if (type == "mt" || type == "math") {
        provider = window.ethereum;
        //   return provider;
    }
    else if (type == "coin98") {
        provider = window.ethereum || window.coin98;
        //   return provider;
    }
    else if (type == "binance") {
        provider = window.BinanceChain;
        //   return provider;
    }
    console.log('providerproviderprovider',provider)
    if (provider) {
        if(provideralone){
            return provider;
        }
        else{
            console.log('providerproviderprovideriffff')
            let data = await GetAccountData(provider,type,switchchain);
            console.log("datadatadata",data)
            let owner = undefined;
            if(data?.UserAccountAddr){
              try{
                let contract = await Instance( data?.providers, data?.currentNetwork?.TradeContract )
                console.log('contractcontract',contract)
                owner = await contract.methods.owner().call()
                owner = owner.toLowerCase();
              }
              catch(err){
                console.log('GetOwnerError',err)
              }
            }
            data.Admin_Address = owner;
            return data;
        }
    }
    else {
        console.log('providerproviderproviderelseee')
        return {display:"Please Connect Wallet",type:"error",error:true,};
    }
}

export const GetAccountData = async (provider,type,switchchain) => {
    try {
        if(type == 'wc'){
            return provider
            .enable()
            .then(async (accounts) => {
              localStorage.setItem("walletConnectType", type);

              // window.$('#connect_modal').modal('hide')
              var web3 = new Web3(provider);
              debugger;
              console.log("CHAINID", web3.currentProvider.chainId);
              if (web3.currentProvider.chainId == config.chainId) {
                localStorage.setItem("walletConnectType", type);
                var balance = 0,
                  setacc = "",
                  currAddr = "";

                var result = JSON.parse(localStorage.walletconnect).accounts;
                setacc = result[0];
                var val = await web3.eth.getBalance(setacc);
                balance = web3.utils.fromWei(String(val));
                currAddr = String(setacc).toLowerCase();
                //  var respval = await AddressUserDetails_GetOrSave_Call(currAddr);
                //var wenbl = await WenlamboValue(currAddr, web3)
                let currchain = await web3.eth.getChainId()
                return {
                    UserAccountAddr: currAddr,
                    providers: provider,
                    UserAccountBal: Number(balance),
                    WalletConnected: "true",
                    Accounts: setacc,
                    web3: web3,
                    load: "true",
                    currentNetwork: ValidChainCheck(currchain),
                }
                // dispatch({
                //   type: Account_Connect,
                //   Account_Detail: {
                //     UserAccountAddr: currAddr,
                //     providers: provider,
                //     UserAccountBal: Number(balance),
                //     WalletConnected: "true",
                //     Accounts: setacc,
                //     web3: web3,
                    
                //     // AddressUserDetails: respval,
                //     //Wen_Bln: Number(wenbl),
                //     load: "true",
                //   },
                // });
              } 
              else {
                return {display:"Please Connect to Ethereum Network",type:"error",error:true,};
              }
            })
            .catch((e) => {});
        }
        else{
            return provider
            .request({ method: "eth_requestAccounts" })
            .then(async (accounts) => {
              console.log("sdkfask");
              localStorage.setItem("walletConnectType", type);
              //window.$('#connect_modal').modal('hide')
              var web3 = new Web3(provider);
            console.log('web3.currentProvider.chainId',web3.currentProvider.chainId)
            let chainId = await web3.eth.getChainId();
            console.log('ValidChainCheck',!ValidChainCheck(chainId, switchchain),chainId, switchchain)
            if(!ValidChainCheck(chainId, switchchain)){
                let switchdata = await SwitchChain(switchchain)
                console.log('switchdataswitchdata',switchdata)
                if(!switchdata?.status){
                  return switchdata;
                }
                provider = await connect_Wallet(type,undefined,true);
                var web3 = new Web3(provider);
            }
            // if (
            //     web3.currentProvider.networkVersion == config.networkVersion ||
            //     web3.currentProvider.chainId == config.chainId
            //   ) {
                console.log("insiddecondition");
                localStorage.setItem("walletConnectType", type);
                var balance = 0,
                  setacc = "",
                  currAddr = "";

                var result = await web3.eth.getAccounts();
                setacc = accounts[0];
                console.log("account", setacc);
                await web3.eth.getBalance(setacc).then(async (val) => {
                  var val = await web3.eth.getBalance(setacc);
                  balance = web3.utils.fromWei(String(val));
                  console.log("balance", balance);
                });
                currAddr = String(setacc).toLowerCase();
                // var respval = await AddressUserDetails_GetOrSave_Call(currAddr);
                //var wenbl = await WenlamboValue(currAddr, web3)
                ////("mt wallet address check ",currAddr);
                let currchain = await web3.eth.getChainId()

                return{
                    UserAccountAddr: currAddr,
                    providers: provider,
                    UserAccountBal: Number(balance),
                    WalletConnected: "true",
                    Accounts: setacc,
                    load: "true",
                    currentNetwork: ValidChainCheck(currchain),
                    web3: web3,
                  }
            //   }
            //   else {
            //     chainIdCheck();
            //     console.log("elsecondition");
            //   }
            })
            .catch((err) => {
              console.log("err in walletconnection", err);
              return {display:"Something went wrong"+err.toString(),type:"error",error:true,};
            });
        }
    }
    catch (err) {
        console.log("GetAccountData_error", err);
        return {display:"Something went wrong"+err.toString(),type:"error",error:true,};
    }
}

const SwitchChain = async (switchchain) => {
    try {
      // Check if MetaMask is installed
      // MetaMask injects the global API into window.ethereum
      const hexString = (switchchain?switchchain:config.DefaultChainId).toString(16);
      console.log('hexstringggg', hexString)
      let chainName = config.chainName;
      let getnetwork = null;
      if(switchchain){
        getnetwork = ValidChainCheck(switchchain);
        chainName = getnetwork?.chainName
      }
      console.log('switchchainswitchchain',chainName,getnetwork)
      if (window.ethereum) {
        try {
          // check if the chain to connect to is installed
          // console.log("window.ethereumwindow.ethereum",window.ethereum);
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: "0x" + hexString }], // chainId must be in hexadecimal numbers
          });
          return { status: true, type: "success", display: "Network Switched,Connect your Wallet" };;
        } catch (error) {
          // console.log("errrrrrrrrrrrrrrr");
          // This error code indicates that the chain has not been added to MetaMask
          // if it is not, then install it into the user MetaMask
          if (error.code === 4902) {
            try {
              let addchain = {
                chainId: "0x" + hexString,
                rpcUrls: [config.RPC_URL],
                "chainName": config.chainName,
                "nativeCurrency": config.chainCurrency,
                "blockExplorerUrls": [config.blockexplorerUrl],
              }
              if(getnetwork){
                addchain = {
                  chainId: "0x" + hexString,
                  rpcUrls: [getnetwork.RPC_URL],
                  "chainName": getnetwork?.chainName,
                  "nativeCurrency": getnetwork?.chainCurrency,
                  "blockExplorerUrls": [getnetwork?.blockexplorerUrl],
                }
              }
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  addchain,
                ],
              });
              return { status: true, type: "success", display: "Network Added,Connect your Wallet" };
            }
            catch (addError) {
              console.log("ddasdfsdg", addError);
              return { status: false, type: "error", display: "Add " + chainName + " to your wallet" };
            }
          }
          console.log("ddasdfsdg1", error,chainName);
          return { status: false, type: "error", display: "Switch to " + chainName };
        }
      }
      else {
        // if no window.ethereum then MetaMask is not installed
        //console('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
        return { status: false, type: "error", display: "Add Wallet to your Browser" };
  
      }
    }
    catch (err) {
      console.log('SwitchChain_error', err);
      return { status: false, type: "error", display: "Error Occured", data: err.toString() };
    }
  }

export const chainIdCheck = async () => {
    // Check if MetaMask is installed
    // MetaMask injects the global API into window.ethereum
    const hexString = config.chainId.toString(16);
    console.log("hexString", hexString);
    if (window.ethereum) {
        try {
            // check if the chain to connect to is installed

            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: config.chainId }], // chainId must be in hexadecimal numbers
            });
            return true;
        } catch (error) {
            // This error code indicates that the chain has not been added to MetaMask
            // if it is not, then install it into the user MetaMask
            if (error.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainId: "0x" + hexString,
                                rpcUrl: config.BNBProvider,
                            },
                        ],
                    });
                    return true;
                } catch (addError) {
                    console.error(addError);
                }
            }
            console.error(error);
        }
    } else {
        // if no window.ethereum then MetaMask is not installed
        //console('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
        return false;
    }
};

export const ValidChainCheck = (chainid, switchchain) => {
    try{
      let keys = Object.keys(network);
      if(keys?.length > 0){
        if(switchchain){
          if(parseInt(switchchain)!=parseInt(chainid)){
            return false;
          }
        }
        let findind = Object.values(network)?.findIndex((val)=>parseInt(val?.CHAIN_ID)==parseInt(chainid))
        if(findind>-1){
          return network[keys[findind]];
        }
        else{
          return false;
        }
      }
      else{
        return false;
      }
    }
    catch(err){
      console.log("ValidChainCheck_error",err)
    }
  }
