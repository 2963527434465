import DETH_ABI from '../ABI/token.json';

import config from './config';

console.log('confignevmnameee',config.ENVname)

var network = {};

if(config.ENVname=='production'){
    
}
else{
    network = {
        //     //      Local

    ETH: {


        //              Sepolia


        currencySymbol: 'ETH',
        COIN_NAME: 'ETH',
        tokenSymbol: 'CAKE',
        tokenAddr: "0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A".toLowerCase(),
        tokenABI: DETH_ABI,
        TradeContract: "0x22b5cddA93CCdaCFae30931F00DEc3e454347633".toLowerCase(),
        ERC721: "0x4B907143546E0AceEE124f1c0aa48551B32B2765".toLowerCase(),////////polygon
        ERC1155: "0x941353dC3ceBabf7eB3e619bB381b5C4261Eb6a3".toLowerCase(),////////polygon
        Stakecontract: "0xF5b79AcA893390A1ee02f95f6e83646a410c660d".toLowerCase(),
        RPC_URL: "https://sepolia.infura.io/v3/be5bdb23c61c4b92b174cb569e57f7b2",
        networkVersion: '11155111',
        chainId: '0xaa36a7',
        CHAIN_ID: 11155111,
        name:"Sepolia",
        EvmName : 'Sepolia_TESTNET',
        label: "Sepolia",
        value: "Sepolia",
        chainCurrency: {
            "name": "Sepolia",
            "symbol": "Sepolia",
            "decimals": 18,
        },
        BlockChain: "Sepolia Testnet",
        chainName: "Sepolia test network",
        blockexplorerUrl: "https://testnet.bscscan.com",
        key: "ETH",         //      key of this object
        RewardtokenAddress: "0x7CAB80ce0E55F46378E493B584eE61aD68878f11".toLowerCase(),
        Rewardtoken: "Cake",
    },

    BSC: {



////////////////////////////binance/////////////////////////////

        currencySymbol: 'BNB',
        COIN_NAME: 'BNB',
        tokenSymbol: 'CAKE',
        tokenAddr: "0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A".toLowerCase(),
        tokenABI: DETH_ABI,
        TradeContract: "0x11F572cB68B739e57C48F40ea7403cae9883EFcb".toLowerCase(),
        ERC721: "0xf95dd67A3126dc7F254a787a34C432Ef44322B11".toLowerCase(),
        ERC1155: "0x99fAb192E6B849c0E1C36403184309595c5a95d9".toLowerCase(),
        Stakecontract: "0x31BD8c861016776F142fc4CF2648D023d02e4e81".toLowerCase(),
        RPC_URL: "https://bsc-testnet-rpc.publicnode.com",
        networkVersion: '97',
        chainId: '0x61',
        CHAIN_ID: 97,
        name:"Binance",
        EvmName : 'BSC_TESTNET',
        label: "BSC",
        value: "Binance",
        chainCurrency: {
            "name": "BNB",
            "symbol": "BNB",
            "decimals": 18,
        },
        BlockChain: "Binance Testnet",
        chainName: "BSC-Testnet",
        blockexplorerUrl: "https://testnet.bscscan.com",
        key: "BSC",
        RewardtokenAddress: "0x7CAB80ce0E55F46378E493B584eE61aD68878f11".toLowerCase(),
        Rewardtoken: "CAKE",
    }

//     //      Local
    }
}

export default network;